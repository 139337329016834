import * as bootstrap from 'bootstrap'
import 'owl.carousel';

let selector = {
    carousel: '.slide-carousel',
    header: '.navbar',
    carouselBlog: '.blog-carousel'
};

$(document).ready(_ => {
    $(selector.carousel).owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        dots: false,
        items: 1,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        animateOut: 'slideOutDown',
        animateIn: 'flipInX',
    })

    $(selector.carouselBlog).owlCarousel({
        loop:true,
        margin:10,
        nav:false,
        dots: false,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        animateOut: 'slideOutDown',
        animateIn: 'flipInX',
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                dots: true,
            },
            600:{
                items:2,
                dots: true,
            },
            1000:{
                items:3,
                nav: true
            }
        }
    })
})